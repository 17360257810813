@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&family=Architects+Daughter&family=Dancing+Script:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  margin-bottom: 3rem;
}

.sidenav {
  text-align: center;
  height: 100%;
  width: 20%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
  overflow: hidden;
}

.mobile-only-header {
  display: none;
}

.mobile-nav {
  display: none;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 30px;
  color: #F9F6EE;
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
}
.nice-green {
  color: rgb(126, 154, 154);
}
.purple {
  color: rgb(72, 61, 139);
}
.contact-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 30px;
  color: rgb(184, 184, 82);
  display: block;
}
.contact-btn:hover {
  color: #f1f1f1;
}
.round-pic {
  margin-top: 2rem;
  position: relative;
  width: 80%;
  height: auto;
  border-radius: 10%;
}

.dotted-border {
  border-style: dashed;
  border-width: thin;
  border-color: grey;
  transition: border 1s;
}

.dotted-border:hover {
  border-style: solid;
  border-color: #0074d9;
}

.nav-title {
  /* font-family: "Amatic SC", cursive !important; */
  font-family: 'Roboto Condensed', sans-serif !important;
  font-weight: 400;
  color: #F9F6EE;
}
.roboto {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-weight: 300;
}
.cursive {
  font-family: "Dancing Script", cursive;
}
/* I know this doesn't make sense. Use this for body text */
.header {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
}

.img-link {
  background-color: white;
  border-radius: 25%;
  height: 48px;
}

.img-link:hover {
  height: 55px;
}

.div-adjust {
  margin-left: 22%;
}

.body-div {
  margin-top: 3%;
}

p {
  font-size: 28px;
  font-weight: 600;
}
span {
  font-size: 28px;
  font-weight: 400;
  /* text-decoration: underline; */
}

.github-body {
  width: 100%;
}

.resumeheader {
  text-decoration: underline;
}

input {
  width: 60%;
  border-radius: 10px;
  border-style: groove;
}
textarea {
  border-radius: 10px;
  width: 80%;
  height: 150px;
}

.project-link {
  font-size: 45px;
  color: #b10dc9;
}

.project-link:hover {
  color: black;
}

.blur {
  -webkit-filter: blur(25px);
          filter: blur(25px);
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.no-blur {
  -webkit-filter: blur(0);
          filter: blur(0);
  transition: -webkit-filter 0.5s 2s ease-in;
  transition: filter 0.5s 2s ease-in;
  transition: filter 0.5s 2s ease-in, -webkit-filter 0.5s 2s ease-in;
}

@-webkit-keyframes example {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes example {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.headshot {
  width: 100%;
  height: 100%;
  display: "relative";
}

@media only screen and (max-width: 600px) {
  .sidenav {
    display: none;
    width: 0%;
    align-items: center;
  }

  .div-adjust {
    margin-left: 0%;
    /* width: "100%"; */
  }

  .body-div {
    /* box-sizing: content-box; */
    width: 100%;
    position: relative;
    padding-top: 20rem;
  }

  .mobile-nav {
    display: block;
  }

  .mobile-only-header {
    display: block;
    z-index: -1000;
    margin-top: 4rem;
    position: fixed;
    padding-bottom: 2rem;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }

  .mobile-wrapper {
    margin-top: 5rem;
  }
}

.marginLeft {
  margin-left: 10%;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  /* max-width: 560px; */
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* max-width: 560px; */
}

.carouselBtn {
  border-width: none !important;
  background-color: white;
  border-color: white;
}

